/************************************************
 * Copyright (C) 2024 Intel Corporation
 ************************************************/
import styled from 'styled-components'
import {
    FunctionComponent,
    Dispatch,
    useState,
    useCallback,
    FormEvent,
    useEffect,
    useRef,
} from 'react'
import { connect } from 'react-redux'
import { LoadingState } from '../models/loadingState'
import SideLinkPanel from '../layouts/sidePanel'
import AiModelHeaderPanel from '../layouts/header'
import AiModelCard from '../layouts/aiModelCard'
import AiModelFooterPanel from '../layouts/footer'
import { LayoutStylePreference } from '../models/layoutStyle'
import { Alert, Form, Spinner } from 'react-bootstrap'
import { getAIModels } from '../store/chatCompletion.slice'
import EmblaCarousel from '../components/emblaCarousel/EmblaCarousel'
import { EmblaOptionsType } from 'embla-carousel'
import '../components/emblaCarousel/css/embla.css'
import { setScrollIntoViewWithId } from '../lib/scroll'
import { genericErrorMessage } from '../models/error'
import React from 'react'
import { getComposeSolution } from '../store/composeGeneration.slice'
import { CopyBlock, dracula } from 'react-code-blocks'

const ComparePanelContainer = styled.div`
    display: flex;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    @media screen and (max-width: 500px) {
        width: 100%;
        height: 100%;
    }
    overflow-y: auto;
    overflow-x: hidden;
    background-color: rgb(24, 24, 24, 0.5);

    /* scroll style-width */
    ::-webkit-scrollbar {
        height: 1rem;
        width: 0.8rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        // background: #f1f1f1;
        // border-radius: 20px;
        background-color: transparent;
        border-radius: 9999px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        // background: #888;
        // border-radius: 20px;
        --tw-border-opacity: 1;
        background-color: hsla(0, 0%, 89%, 0.8);
        border-color: rgba(255, 255, 255, var(--tw-border-opacity));
        border-radius: 9999px;
        border-width: 1px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`

const CompareHorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
    margin-top: 3.5rem;
`
const CompareBodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    width: 100%;
    background-color: #fefefe;
    overflow-y: auto;
`
const SidePanelComponent = styled.div`
    display: block;
    @media screen and (max-width: 600px) {
        display: none;
    }
`

const MainSection = styled.section`
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 0;
    padding: 2rem 1rem 3rem 1rem;
    background-color: #e8e8e8; //rgb(12, 12, 12, 1);
    margin: 1rem 1.5rem;
`

const AlertComponent = styled(Alert)`
    display: flex;
    margin-top: 1rem;
    justify-content: center;
    border-radius: 0;
    width: 100%;
`

const SpinnerDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    width: 100%;
`
const CreateButton = styled.button`
    font-family: 'IntelOne Text';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    text-decoration: none;
    color: #fff;
    background-color: #8f5da2;
    margin: 1.5rem;
    border: none;
    min-width: 10rem;
    height: 2rem;
    border-radius: 0;
    border: none;
    &:disabled {
        opacity: 0.1;
        --tw-text-opacity: 1;
    }
`

const ViewPortControl = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    //margin-top: 20px;
    border-top: solid 1px;
    border-color: rgb(101, 49, 113);
    //border: 1px solid rgb(101, 49, 113);
    min-height: 15rem;
    margin: 1.2rem 2rem;
`

const ButtonTab = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgb(44, 44, 44);
    border-radius: 0px;
    border: none;
    color: rgb(224, 224, 224);
    font-family: 'IntelOne Display';
    font-size: 0.8rem;
    font-weight: 500;
    padding: 3px 12px;
    text-transform: capitalize;
    margin-right: 0.5rem;
    &:hover {
        color: rgb(224, 224, 224);
        border: none;
        font-family: 'IntelOne Display';
        font-size: 0.8rem;
        font-weight: 500;
    }
    &:focus {
        color: rgb(224, 224, 224);
        border: none;
        font-family: 'IntelOne Display';
        font-size: 0.8rem;
        font-weight: 500;
    }
    &.active {
        border-bottom: 4px solid #61dafb; //4px solid rgb(84, 255, 46);
        font-weight: 500;
    }
`
const OutputInnerContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
   // justify-content: center;
    width: 100%;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
`
export interface IComposeContainerProps {
    getAIModels: typeof getAIModels
    getAIModelsResultData: any
    getAIModelsLoading: LoadingState
    getAIModelsError: any
    getComposeSolution: typeof getComposeSolution
    getComposeSolutionResult: any
    getComposeSolutionLoading: LoadingState
    getComposeSolutionError: any
}

const ComposeContainer: FunctionComponent<IComposeContainerProps> & {
    defaultProps: Partial<IComposeContainerProps>
} = ({
    getAIModels,
    getAIModelsResultData,
    getAIModelsLoading,
    getAIModelsError,
    getComposeSolution,
    getComposeSolutionResult,
    getComposeSolutionLoading,
    getComposeSolutionError,
}: IComposeContainerProps) => {
    const [compareLLMModelsList, setCompareLLMModelsList] = useState<any>()

    const [selectedSolutionName, setSelectedSolutionName] = useState<
        string | undefined
    >(undefined)

    const [selectedLLMModelName, setSelectedLLMModelName] = useState<
        string | undefined
    >(undefined)

    const [selectedGaudiCards, setSelectedGaudiCards] = useState<number>(1)

    const [showComposeSolution, setShowComposeSolution] =
        useState<boolean>(false)

    const gaudiCardsRef = useRef(selectedGaudiCards)
    gaudiCardsRef.current = selectedGaudiCards

    const llmModelsRef = useRef(compareLLMModelsList)
    llmModelsRef.current = compareLLMModelsList

    const composeModels = ["llava-hf/llava-1.5-7b-hf", "docusum-intel/neural-chat-7b-v3-3", "rag-teknium/openhermes-2.5-mistral-7b", "meta/llama-7b" , "meta/llama-70b"]
    useEffect(() => {
        getAIModels('?count=50')
    }, [])

    useEffect(() => {
        if (
            getAIModelsResultData &&
            getAIModelsResultData.models 
        ) {
            const modelList = getAIModelsResultData.models.map((item: any) => {
                return {
                    ...item,
                    isChecked: false,
                    showRadioOptionPanel: item.isActive ? true : false,
                    isRagModel: item.name.startsWith('rag-') ? true : false,
                    isCompatible:
                        selectedGaudiCards >= Number(item.tags['GaudiCards']),
                }
            })

            setCompareLLMModelsList(
                modelList.filter(
                    (item: any) =>
                        Number(item.tags['GaudiCards']) >= 0 &&
                       composeModels.includes(item.name)
                )
            )
        }
    }, [
        getAIModelsResultData,
        selectedGaudiCards,
        setCompareLLMModelsList,
    ])

    const onCardClick = useCallback(
        (
            event: FormEvent<HTMLElement>,
            modelName: string,
            displayName: string,
            subType: any,
            gaudiCards: number
        ) => {
            if (gaudiCardsRef.current >= gaudiCards) {
                setSelectedLLMModelName(modelName)

                setCompareLLMModelsList((prev: any) =>
                    prev.map((item: any) => {
                        if (modelName === item.name) {
                            item.isChecked = !item.isChecked
                        } else {
                            item.isChecked = false
                        }
                        return item
                    })
                )
            }
        },
        [setCompareLLMModelsList, compareLLMModelsList, selectedGaudiCards]
    )
    useEffect(() => {
        if (showComposeSolution) {
            setScrollIntoViewWithId('scrollUpToId')
        }
    }, [showComposeSolution])

    const handleCreateClick = () => {
        getComposeSolution(
            `?solution=${selectedLLMModelName}&&number-of-gaudis=${selectedGaudiCards}`
        )
    }

    useEffect(() => {
        if (getComposeSolutionResult) {
            setShowComposeSolution(true)
        }
    }, [getComposeSolutionResult])

    const handleDownloadClick = useCallback(() => {
        if (getComposeSolutionResult) {
            let blob = new Blob(['\uFEFF' + getComposeSolutionResult], {
                type: 'text/yaml;charset=utf-18',
            })

            let url = window.URL.createObjectURL(blob)
            let link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'compose.yaml')
            document.body.appendChild(link)
            link.click()
            link.remove()
        }
    }, [getComposeSolutionResult])

    const OPTIONS: EmblaOptionsType = { slidesToScroll: 'auto' }

    const LLM_SLIDES = (
        <div>
            {llmModelsRef.current &&
                llmModelsRef.current.map((item: any, index: number) => (
                    <div key={`card-${index}`}>
                        <AiModelCard
                            imageURL={item.logo}
                            modelName={item.name}
                            displayName={item.displayName }
                            publishedBy={item.author}
                            description={item.description}
                            onCardClickCallBack={(e: any) => {
                                onCardClick(
                                    e,
                                    item.name,
                                    item.displayName,
                                    item?.tags,
                                    Number(item.tags['GaudiCards'])
                                )
                            }}
                            category={item?.tags['Feature']}
                            renderStyle={LayoutStylePreference.vStyle}
                            isActive={
                                gaudiCardsRef.current >=
                                Number(item.tags['GaudiCards'])
                            } //disable if gaudi cards aren't enough
                            showRadioOptionPanel={item.showRadioOptionPanel}
                            isChecked={item.isChecked}
                            index={index}
                            solution={selectedSolutionName + '-llm'}
                        />
                    </div>
                ))}
        </div>
    )

    const llmSlidesRef = useRef(LLM_SLIDES)
    llmSlidesRef.current = LLM_SLIDES

    const selectSolutionSection = (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'left',
                backgroundImage: `url('/aiModelImage/gaudi.png')`,
                backgroundSize: '100%',
                backgroundPosition: 'center',
                height: '13rem',
                backgroundRepeat: 'no-repeat',
                paddingTop: '1.5rem',
                marginLeft:'5%',
                marginRight: '5%',

            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: '2%',
                    color: 'white'
                }}
            >
                <h6>Gaudi 2 Cards:</h6>
                <Form.Control
                    id="input"
                    name="input"
                    type="number"
                    onChange={(event: any) => {
                        setSelectedGaudiCards(event.target.value)
                    }}
                    min={1}
                    max={8}
                    defaultValue={1}
                />
            </div>
        </div>
    )

    const selectLLMSection = (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: '7%',
                }}
            >
                <h6>Choose a Solution:</h6>
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                }}
            >
                {llmModelsRef.current && llmModelsRef.current.length > 0 ? (
                    <EmblaCarousel
                        slides={llmSlidesRef.current}
                        options={OPTIONS}
                    />
                ) : (
                    ''
                )}
            </div>
        </div>
    )

    return (
        <ComparePanelContainer>
            <AiModelHeaderPanel></AiModelHeaderPanel>
            <CompareHorizontalContainer>
                <SidePanelComponent>
                    <SideLinkPanel></SideLinkPanel>
                </SidePanelComponent>

                <CompareBodyContainer>
                    {getAIModelsError && (
                        <AlertComponent
                            variant="danger"
                            show={getAIModelsError ? true : false}
                        >
                            {genericErrorMessage}
                        </AlertComponent>
                    )}

                    {getAIModelsLoading !== LoadingState.Pending ? (
                        <MainSection style={{ marginTop: '1.6rem' }}>
                            <div>{selectSolutionSection}</div>

                            <div
                                style={{
                                    margin: '1rem 3rem 2rem 3rem',
                                    borderTop: '1px solid rgb(143, 93, 162)',
                                }}
                            ></div>
                            <div>{selectLLMSection}</div>
                            <div
                                style={{
                                    margin: '1rem 3rem 2rem 3rem',
                                    borderTop: '1px solid rgb(143, 93, 162)',
                                }}
                            ></div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                }}
                            >
                                <CreateButton
                                    disabled={!selectedLLMModelName}
                                    onClick={handleCreateClick}
                                >
                                    Get
                                </CreateButton>
                            </div>

                            {showComposeSolution == true && (
                                <ViewPortControl>
                                    <AlertComponent
                                        style={{ width: '100%' }}
                                        variant="danger"
                                        show={
                                            getComposeSolutionError
                                                ? true
                                                : false
                                        }
                                    >
                                        {genericErrorMessage}
                                    </AlertComponent>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <div id="scrollUpToId"></div>

                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    padding: '0.5rem',
                                                }}
                                            >
                                                <ButtonTab className={'active'}>
                                                    YAML
                                                </ButtonTab>
                                                <ButtonTab
                                                    onClick={() =>
                                                        handleDownloadClick()
                                                    }
                                                >
                                                    Download
                                                </ButtonTab>
                                            </div>
                                            <OutputInnerContainer>
                                                <pre
                                                    style={{
                                                        width: '70rem',
                                                        padding: '1rem',
                                                    }}
                                                >
                                                    <CopyBlock
                                                        theme={dracula}
                                                        text={
                                                            getComposeSolutionResult
                                                        }
                                                        language={'yaml'}
                                                        showLineNumbers={false}
                                                        codeBlock
                                                    />
                                                </pre>
                                            </OutputInnerContainer>
                                        </div>
                                    </div>
                                </ViewPortControl>
                            )}

                            {getComposeSolutionLoading ===
                                LoadingState.Pending && (
                                <SpinnerDiv>
                                    <Spinner animation="border" variant="light">
                                        <span className="visually-hidden">
                                            Loading...
                                        </span>
                                    </Spinner>
                                </SpinnerDiv>
                            )}
                        </MainSection>
                    ) : (
                        <SpinnerDiv>
                            <Spinner animation="border" variant="light">
                                <span className="visually-hidden">
                                    Loading...
                                </span>
                            </Spinner>
                        </SpinnerDiv>
                    )}

                    <AiModelFooterPanel></AiModelFooterPanel>
                </CompareBodyContainer>
            </CompareHorizontalContainer>
        </ComparePanelContainer>
    )
}

ComposeContainer.defaultProps = {}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        getAIModels: (qParam: any) => {
            dispatch(getAIModels(qParam))
        },
        getComposeSolution: (qParam: any) =>
            dispatch(getComposeSolution(qParam)),
    }
}

const mapStateToProps = (state: any) => {
    return {
        getAIModelsResultData: state.getAIModelsResult.data,
        getAIModelsLoading: state.getAIModelsResult.loading,
        getAIModelsError: state.getAIModelsResult.error,

        getComposeSolutionResult: state.getComposeSolutionResult.data,
        getComposeSolutionLoading: state.getComposeSolutionResult.loading,
        getComposeSolutionError: state.getComposeSolutionResult.error,
    }
}

type StateToPropsType = ReturnType<typeof mapStateToProps>
type DispatchToPropsType = typeof mapDispatchToProps

export default connect<StateToPropsType, DispatchToPropsType>(
    mapStateToProps,
    mapDispatchToProps
)(ComposeContainer)
