import { HOST_ENV_DOMAIN_MAPPING } from '../config/service'

export function getHostEnv(APP_DOMAIN: string): string | null {
    if (APP_DOMAIN == HOST_ENV_DOMAIN_MAPPING.localhost) {
        return 'localhost'
    }
    if (APP_DOMAIN == HOST_ENV_DOMAIN_MAPPING.dev) {
        return 'dev'
    }
    if (APP_DOMAIN == HOST_ENV_DOMAIN_MAPPING.staging) {
        return 'staging'
    }
    if (APP_DOMAIN == HOST_ENV_DOMAIN_MAPPING.production) {
        return 'production'
    }
    return null
}
export function isDevAndLocal(APP_DOMAIN: string): boolean {
    const hostEnv = getHostEnv(APP_DOMAIN)
    if (hostEnv == 'dev' || hostEnv == 'localhost') {
        return true
    }
    return false
}
export function isStaging(APP_DOMAIN: string): boolean {
    const hostEnv = getHostEnv(APP_DOMAIN)
    if (hostEnv == 'staging') {
        return true
    }
    return false
}
export function isProd(APP_DOMAIN: string): boolean {
    const hostEnv = getHostEnv(APP_DOMAIN)
    if (hostEnv == 'production') {
        return true
    }
    return false
}
