import { APP_DOMAIN, HOST_ENV_DOMAIN_MAPPING } from '../../config/service'
import { isDevAndLocal } from '../../lib/host'

const baseURL = isDevAndLocal(APP_DOMAIN)
    ? `https://${HOST_ENV_DOMAIN_MAPPING.dev}/api/ai`
    : `https://${APP_DOMAIN}/api/ai`

export const audioChatPythonCode = `import base64
from openai import OpenAI

def encode_audio(audio_path):
    with open(audio_path, "rb") as audio_file:
        return base64.b64encode(audio_file.read()).decode('utf-8')
        
# Path to your image
audio_path = "path_to_your_audio.wav"

# Getting the base64 string
base64_audio = encode_audio(audio_path)

client = OpenAI(
    base_url = "${baseURL}",
    api_key = "$API_KEY_REQUIRED_IF_EXECUTING_OUTSIDE_INTC"
)

completion = client.chat.completions.create(
    model="tts_llm",
    messages=[
        {
            "role": "user",
            "content": [
                {
                    "type": "text",
                    "text": "What’s in this audio file?"
                },
                {
                    "type": "audio_url",
                    "audio_url": {
                        "url": f"data:audio/wav;base64,{base64_audio}",
                    },
                },
            ],
        }
    ],
    max_tokens=300,
)

print(completion.choices[0].message)
`

export const audioSupportedFiles = [
    'audio/wav',
    'audio/mp3',
    'audio/mp4',
    'audio/*',
]
