import React, { useRef } from 'react'
import { EmblaOptionsType } from 'embla-carousel'
import { DotButton, useDotButton } from './EmblaCarouselDotButton'
import {
    PrevButton,
    NextButton,
    usePrevNextButtons,
} from './EmblaCarouselArrowButtons'
import useEmblaCarousel from 'embla-carousel-react'

type PropType = {
    slides: any
    options?: EmblaOptionsType
}

const EmblaCarousel: React.FC<PropType> = (props) => {
    const { slides, options } = props
    const [emblaRef, emblaApi] = useEmblaCarousel(options)
const slidesRef = useRef(slides)

    const { selectedIndex, scrollSnaps, onDotButtonClick } =
        useDotButton(emblaApi)

        //console.log(slides)
        // slides.props.children.map((item: any, index: number) => {
        //     console.log(item.props.children)
        // })



    const {
        prevBtnDisabled,
        nextBtnDisabled,
        onPrevButtonClick,
        onNextButtonClick,
    } = usePrevNextButtons(emblaApi)

    return (
        <section className="embla">
            <div className="embla__controls">
                <div className="embla__buttons">
                    <div
                        style={{
                            height: '100%',
                            position: 'relative',
                            width: '50px',
                            background: 'transparent',
                        }}
                    >
                        <PrevButton
                            style={{
                                position: 'absolute',
                                left: '0px',
                                top: '6.25rem',
                                border: '1px solid #00a3fb',
                                width: '2.8rem',
                                height: '2.8rem',
                            }}
                            onClick={onPrevButtonClick}
                            disabled={prevBtnDisabled}
                        />
                    </div>
                </div>
            </div>
            <div className="embla__viewport" ref={emblaRef}>
                <div className="embla__container" ref={slidesRef}>
                    {slides.props.children.map((item: any, index: number) => (
                        <div
                            className="embla__slide"
                            key={index}
                            style={
                                item.props.children.props.isChecked === true
                                    ? { borderBlock: 'solid 2px' }
                                    : {}
                            }
                        >
                            <div className="embla__slide__number">
                                {item.props.children}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="embla__controls">
                <div className="embla__buttons">
                    <div
                        style={{
                            height: '100%',
                            position: 'relative',
                            width: '50px',
                            background: 'transparent',
                        }}
                    >
                        <NextButton
                            style={{
                                position: 'absolute',
                                left: '15px',
                                top: '6.25rem',
                                border: '1px solid #00a3fb',
                                width: '2.8rem',
                                height: '2.8rem',
                            }}
                            onClick={onNextButtonClick}
                            disabled={nextBtnDisabled}
                        />
                    </div>
                </div>

                <div className="embla__dots" style={{ display: 'none' }}>
                    {scrollSnaps.map((_, index) => (
                        <DotButton
                            key={index}
                            onClick={() => onDotButtonClick(index)}
                            className={'embla__dot'.concat(
                                index === selectedIndex
                                    ? ' embla__dot--selected'
                                    : ''
                            )}
                        />
                    ))}
                </div>
            </div>
        </section>
    )
}

export default EmblaCarousel
