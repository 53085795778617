import { combineReducers } from '@reduxjs/toolkit'

import {
    getAIModelsResult,
    postChatCompletionResult,
    postRetrievalQueryResult,
} from './chatCompletion.slice'
import { submitImageGenerationResult } from './imageGeneration.slice'
import { getComposeSolutionResult } from './composeGeneration.slice'
const rootReducer = combineReducers({
    getAIModelsResult,
    postChatCompletionResult,
    postRetrievalQueryResult,
    submitImageGenerationResult,
    getComposeSolutionResult,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
